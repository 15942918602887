.ig-slider-main-container li {
    border-radius: 12px;
}

.ig-slider-main-container-rel {
    position: relative !important;
    overflow: scroll !important;
    height: auto;
    /* padding-top: 60px; */

    margin-top: 47px;
}

.ig-slider-main-container-rel::-webkit-scrollbar {
    display: none;
}

.vms_carousel_previousdiv {
    left: unset !important;
    right: 40px !important;
}

.ig-slider-main-container .fRTSPc {
    left: unset !important;
    right: 40px !important;
    top: -50px;
}

.ig-slider-main-container-rel :global .vms_carousel_previousdiv {
    left: unset;
    right: 34px;
    top: 9px;
    z-index: 9;
    visibility: visible !important;
    opacity: unset !important;
}

.ig-slider-main-container-rel :global .vms_carousel_nextdiv {
    left: unset;
    right: 0px;
    top: 9px;
    z-index: 9;
    visibility: visible !important;
    opacity: unset !important;
    /* min-width: 24px; */
}

.ig-slider-main-container-rel :global .vms_carousel_nextbutton {
    width: 24px;
    height: 24px;
    background: #027BFF;
    opacity: 0.9;
    border-radius: 50px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    color: #FFFFFF;
    min-width: 24px;
    min-height: 24px;
}

.ig-slider-main-container-rel :global .vms_carousel_previousbutton {
    width: 24px;
    height: 24px;
    background: rgba(34, 34, 34, 0.10);
    /* min-width: 24px;
    min-height: 24px; */
    background: #027BFF;
    opacity: 0.9;
    border-radius: 50px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    color: #FFFFFF;
    min-width: 24px;
    min-height: 24px;
}

.ig-slider-main-container-rel :global .vms_carousel_nextbutton svg,
.ig-slider-main-container-rel :global .vms_carousel_previousbutton svg {
    color: #ffffff !important;
    display: flex;
    margin: auto;
    width: 4px !important;
    height: 10px !important;
    min-width: 4px !important;
    min-height: 10px !important;
    stroke-width: 3px;
}

/** Slider Navigation Button*/
.left-btn-disable :global .vms_carousel_previousbutton {
    pointer-events: none;
    background: rgba(34, 34, 34, 0.10);
}

.right-btn-disable :global .vms_carousel_nextbutton {
    cursor: not-allowed !important; 
    pointer-events: none !important;
    background: rgba(34, 34, 34, 0.10);
}

.mobile-explore-with-ig-container :global .vms_carousel_childitems {
    background: rgba(0, 0, 0, 0.02);
}




@media screen and (min-width:320px) and (max-width:767px) {

.ig-slider-main-container-rel :global .vms_carousel_childcontainer{
    transform: translate3d(0px, 0px, 0px) !important;
}
.ig-slider-main-container-rel {
    margin-top: 20px;
}

}